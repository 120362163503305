.swiper {
  height: 100dvh;
  margin-bottom: 20px;
}

.container {
  width: 100%;
  height: 100%;
}

.imageContainer {
  width: 100%;
  height: 100%;
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  /* z-index: 1; */
}

.swiperSlide {
  position: relative;
  width: 100%;
  height: 100%;
}

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff !important;
  text-align: center;
  z-index: 2;
}

.title,
.subtitle {
  text-shadow: 1px 1px 1px #000;
}

.title {
  color: #fff;
}

.subtitle {
  font-size: 22px;
}

.swiper .swiper-pagination {
  position: absolute;
  bottom: 120px;
  left: 50%;
  transform: translateX(-50%);
}

/* .background {
  color: white;
} */

.from-group {
  margin-bottom: 1rem;
}
/* Add or update these styles in Slider.module.css */
.swiper {
  height: 400px; /* Adjust the height as needed */
}

.swiperSlide {
  height: 100%; /* Ensure slides take the full height of the swiper */
}

.container {
  height: 100%; /* Ensure the container takes the full height of the swiper */
}

.imageContainer {
  height: 100%; /* Ensure the image container takes the full height of the swiper */
}

.image {
  height: 100%; /* Ensure the image takes the full height of the container */
  object-fit: cover; /* Maintain aspect ratio and cover the entire container */
}
