.mySwiper {
  width: 100%;
  height: 100%;
}

.mySwiper .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
	width: 300px !important;
	height: 100% !important;
	border-radius: 10px;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.swiper-button-prev::after,
.swiper-button-next::after {
  color: #fff;
}