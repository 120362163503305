.swiper {
    height: 100dvh;
}

.container {
    width: 100%;
    height: 100%;
    position: relative;
}

.container::before {
    position: absolute;
    content: '';
    inset: 0;
    background-image: linear-gradient(to bottom, rgba(0,0,0,0.8), rgba(0,0,0,0.8));
}

.imageContainer {
    width: 100%;
    height: 100%;
}

.image {
    width: 100% !important;
    height: 100%;
    object-fit: cover;
}

.swiperSlide {
    width: 100% !important;
    margin: 0;
}

.content {
    position: absolute;
    content: '';
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    text-align: center;
}

.title,
.subtitle {
    text-shadow: 1px 1px 1px #000;
}

.title {
    color: #fff;
}

.subtitle {
    font-size: 22px;
}

.swiper .swiper-pagination {
    position: absolute;
    bottom: 120px;
    left: 50%;
    transform: translateX(-50%);
}