.card {
  position: relative;
  width: 380px;
  height: 490px;
  margin: 50px;
  border-radius: 50px;
  border: solid;
  overflow: hidden;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
  transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.card-container {
  width: 100%;
  position: relative;
  padding-top: calc(490 / 380 * 100%); /* Aspect ratio calculation */
}

.card__image {
  height: 400px;
  width: 280px;
  background-color: #000;
}

@media (max-width: 768px) {
  .card__image {
    max-width: 200px;
    max-height: 180px;
  }
}

@media (max-width: 480px) {
  .card__image {
    max-width: 150px;
    max-height: 180px;
  }
}

.card__image img {
  width: 400px;
  height: 280px;
  object-fit: cover;
  object-position: center;
  transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .card__image img {
    max-width: 200px; /* Adjust max-width for smaller screens */
    max-height: 210px; /* Adjust max-height for smaller screens */
  }
}

/* Media query for even smaller screens */
@media (max-width: 480px) {
  .card__image img {
    max-width: 250px; /* Further adjust max-width for very small screens */
    max-height: 180px; /* Further adjust max-height for very small screens */
  }
}

.card__content {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  background-color: #fff;
  transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
}

@media (max-width: 768px) {
  .card__content {
    padding: 3%; /* Adjust padding for smaller screens */
  }
}

/* Media query for even smaller screens */
@media (max-width: 480px) {
  .card__content {
    padding: 2%; /* Further adjust padding for very small screens */
  }
}

.card__title {
  font-size: 21px;
  font-weight: bold;
  color: #000;
}
/* Media query for smaller screens */
@media (max-width: 768px) {
  .card__title {
    font-size: 0.8em;
  }
}

/* Media query for even smaller screens */
@media (max-width: 480px) {
  .card__title {
    font-size: 0.5em;
  }
}

.card__text {
  font-size: 1em;
  line-height: 1.5;
}
/* Media query for smaller screens */
@media (max-width: 768px) {
  .card__text {
    font-size: 0.5em; /* Adjust font size for smaller screens */
  }
}

/* Media query for even smaller screens */
@media (max-width: 480px) {
  .card__text {
    font-size: 0.4em; /* Further adjust font size for very small screens */
  }
}

.card__button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #000;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  width: 300px;
}
/* Media query for smaller screens */
@media (max-width: 768px) {
  .card__button {
    max-width: 200px; /* Adjust max-width for smaller screens */
  }
}

/* Media query for even smaller screens */
@media (max-width: 480px) {
  .card__button {
    max-width: 150px; /* Further adjust max-width for very small screens */
  }
}

.card__image {
  height: 100px;
  width: 100%;
  object-fit: cover;
  background-position: center;
}

.background-image {
  position: relative; /* Ensure positioning context for the overlay */
  background-image: url("./background.jpg");
  background-size: cover;
  background-position: center;
}

.background-image::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Black overlay with 50% opacity */
}

.overlay-content {
  position: relative;
  z-index: 2; /* Ensure the content is above the overlay */
  color: #fff; /* Make sure the text is readable */
  padding: 20px; /* Add padding for better spacing */
}


.display_button {
  display: inline-block;
  padding: 15px 20px;
  background-color: #000;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  width: 300px;
  height: 60px;
  text-align: center;
}
