.cart-badge {
    position: absolute;
    content: '';
    background-color: var(--color-main);
    top: 10px;
    right: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
}

.form-check {
    padding-left: 0 !important;
}

.home-phone-page .PhoneInputInput {
    color: #fff !important;
}

.PhoneInputInput,
.PhoneInputInput:focus-visible {
    border: none !important;
}

.react-tel-input .selected-flag .flag {
    left: 50%;
    transform: translateX(-50%);
}