.active {
    color: var(--color-main) !important;
    position: relative;
}

.active::after {
    position: absolute;
    content: '';
    bottom: 20px;
    left: 0;
    width: 100%;
    height: 2px;
    border-radius: 8px;
    background-color: var(--color-main);
}

@media screen and (max-width: 991px) {
    .active::after {
        bottom: 0px;
    }
}