.toggle-button-container {
  position: fixed;
  bottom: 20px;
  left: 20px; /* Changed from 'right' to 'left' */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.toggle-button {
  background-color: #25d366; /* WhatsApp green */
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 24px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.toggle-button:hover {
  transform: scale(1.1);
}

.options {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  transition: max-height 0.3s ease, opacity 0.3s ease;
  max-height: 0;
  overflow: hidden;
  opacity: 0;
}

.options.open {
  max-height: 350px; /* Adjusted for more options */
  opacity: 1;
}

.option {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 24px;
  margin-bottom: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-decoration: none;
}

.option.whatsapp {
  background-color: #25d366; /* WhatsApp green */
}

.option.call {
  background-color: #34b7f1; /* Call blue */
}

.option.messenger {
  background-color: #0084FF; /* Messenger blue */
}

.option.email {
  background-color: #D44638; /* Email red */
}

.option.telegram {
  background-color: #0088cc; /* Telegram blue */
}

.option.facebook {
  background-color: #3b5998; /* Facebook blue */
}

.option.instagram {
  background-color: #C13584; /* Instagram gradient pink */
}

.toggle-icon {
  font-size: 32px;
}
