/* Global Styles */
.navbar-nav {
  display: flex;
  flex-direction: column; /* Stack items vertically on mobile */
  width: 100%; /* Full width */
}

/* Nav Item Styling */
.nav-item {
  margin-bottom: 1rem; /* Space between items */
  position: relative; /* Required for dropdown positioning */
}

/* Dropdown Menu Styling */
.dropdown-menu {
  width: 100%; /* Full-width for easier access */
  box-shadow: none; /* Remove shadow */
  z-index: 9999 !important; /* Ensure it appears above other elements */
  background-color: white; /* Set background color */
  position: absolute; /* Position absolutely relative to the parent */
  top: 100%; /* Position below the button */
  left: 0; /* Align to the left of the parent */
  display: none; /* Initially hidden */
}
.dropdown-menu.show {
  display: block !important; /* Show dropdown when active */
}

/* Search Container Styling */
.search-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.search-input {
  padding-right: 2.5rem; /* Space for the search button */
  width: 100%; /* Full width */
  box-sizing: border-box; /* Include padding in width */
  z-index: 1;
}

.search-btn {
  position: absolute;
  right: 0.5rem;
  top: 70%; /* Center vertically */
  transform: translate(-30%, -20%);
  border: none;
  background: transparent;
  color: #6c757d;
  cursor: pointer;
  padding: 0; /* Ensure no additional padding is applied */
  z-index: 2; /* Ensure it appears above the input text */
}

.search-btn:hover {
  color: #495057; /* Darken on hover for better UX */
}

.custom-color {
  color: #ffffff !important;
}

/* Media Queries for Responsiveness */
@media (max-width: 992px) {
  .navbar-nav {
    flex-direction: column; /* Stack items vertically on mobile */
  }

  .search-container {
    padding: 15px;
  }

  .search-input,
  .search-btn {
    font-size: 14px;
  }
}

@media (max-width: 576px) {
  .search-container {
    padding: 10px;
  }

  .search-input,
  .search-btn {
    font-size: 12px;
  }
}

@media (max-width: 576px) {
  .dropdown-menu {
    position: relative; /* Relative positioning to the parent */
    width: 100%; /* Full width */
    z-index: 9999 !important; /* Keep it above other elements */
  }
  .btn {
    display: block; /* Ensure the button is displayed */
    z-index: 9999; /* Ensure the button is on top of other elements */
  }
  .custom-color {
    color: #000000 !important;
  }
}
