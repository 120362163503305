.image-container {
  display: inline-block; /* Arrange images horizontally */
  margin: 10px; /* Add some margin between images */
  width: 350px; /* Set image width */
  height: 450px; /* Set image height */
  position: relative; /* Enable positioning for overlays */
}




@media screen and (max-width: 992px) {
  .image-container {
    width: calc(
      33.33% - 20px
    ); /* Three images per row on medium screens with margin */
    margin: 10px;
  }
}

@media screen and (max-width: 768px) {
  .image-container {
    width: calc(
      50% - 20px
    ); /* Two images per row on small screens with margin */
    margin: 10px;
  }
}

@media screen and (max-width: 576px) {
  .image-container {
    width: calc(
      100% - 20px
    ); /* One image per row on extra small screens with margin */
    margin: 10px;
  }
}

.image-container img {
  width: 100%; /* Stretch image to fill container */
  height: 100%; /* Stretch image to fill container */
}

@media screen and (max-width: 768px) {
  .image-container {
    width: 45%; /* Two images per row on small devices */
  }
}

@media screen and (max-width: 576px) {
  .image-container {
    width: 100%; /* One image per row on extra small devices */
    margin: 10px 0; /* Adjust margin for better spacing */
  }
}

.image-container:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0; /* Initially hidden */
  transition: opacity 0.3s ease; /* Smooth opacity transition */
  background-color: black;
}

@media screen and (max-width: 768px) {
  .image-container:after {
    top: 3px; /* Adjust top position for small screens */
    bottom: 3px; /* Adjust bottom position for small screens */
    left: 3px; /* Adjust left position for small screens */
    right: 3px; /* Adjust right position for small screens */
  }
}

@media screen and (max-width: 576px) {
  .image-container:after {
    top: 2px;
    bottom: 2px;
    left: 2px;
    right: 2px;
  }
}

.image-container:hover:after {
  opacity: 0.8; /* Black background with 0.8 opacity on hover */
}
.image-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center content */
  opacity: 0; /* Initially hidden */
  transition: opacity 0.3s ease; /* Smooth opacity transition */
  text-align: center; /* Center text horizontally */
  color: white; /* Text color */
  font-size: 32px; /* Increased font size */
  padding: 10px; /* Add some padding */
  display: none; /* Initially hide text */
}
.image-container:hover .image-text {
  display: block; /* Show text on hover */
  opacity: 1;
  z-index: 1;
}

@media screen and (max-width: 768px) {
  .image-text {
    font-size: 20px; /* Decrease font size for small screens */
    padding: 6px; /* Decrease padding for small screens */
  }
}

@media screen and (max-width: 576px) {
  .image-text {
    font-size: 18px; /* Decrease font size for extra small screens */
    padding: 4px; /* Decrease padding for extra small screens */
  }
}
